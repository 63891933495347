import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import loadable from 'react-loadable';
import LoadingComponent from 'components/LoadingComponent';
import NotFound from 'page/NotFound';

const AsyncPage = loadable({
  loader: () => import('./page/'),
  loading: LoadingComponent,
});

const AsyncNeedVerify = loadable({
  loader: () => import('./needVerify/'),
  loading: LoadingComponent,
});

const AsyncUi = loadable({
  loader: () => import('./ui/'),
  loading: LoadingComponent,
});

class App extends Component {
  componentDidUpdate(prevProps) {
    // if (
    //   prevProps &&
    //   prevProps.location &&
    //   this.props.location &&
    //   this.props.location.pathname !== prevProps.location.pathname
    // ) {
    //   window.scrollTo(0, 0);
    // }
  }

  render() {
    let redirectUiPath = '/page/login';

    if (window.companyId && window.userId) {
      redirectUiPath = `/ui/${window.companyId}/${window.userId}/home`;
    }
    if (window.userId) {
      if (!window.companyId || !window.personId) {
        redirectUiPath = '/need-verify';
      }
    }

    return (
      <Switch>
        <Route
          exact
          path="/page/test-loading-error"
          render={props => <LoadingComponent error={true} />}
        />
        <Route
          exact
          path="/page/test-loading-delay"
          render={props => <LoadingComponent pastDelay={true} />}
        />
        <Route path="/need-verify" component={AsyncNeedVerify} />
        <Route path="/page" component={AsyncPage} />
        <Route path="/ui/:companyId/:userId" component={AsyncUi} />
        <Redirect exact from="/ui" to={redirectUiPath} />
        <Redirect exact from="/" to={redirectUiPath} />
        <Route component={NotFound} />;
      </Switch>
    );
  }
}

export default App;
