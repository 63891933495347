import _ from 'lodash';
import { createHashHistory } from 'history';
import ApolloClient from 'apollo-boost';
import graphqlUrl from 'helpers/backend';
// import { fbAuth } from 'helpers/firebase';

const _history = createHashHistory();

// Listen for changes to the current location.

_history.listen((location, action) => {
  // window.Intercom('update', {
  //   locationPathName: location.pathname,
  //   locationSearch: location.search,
  // });
});

const { location } = _history;

window.onAuthUrl =
  location.pathname.startsWith('/ui/') && !location.pathname.endsWith('/logout')
    ? location.pathname
    : null;

const clientState = {
  defaults: {
    isLoggedIn: false,
    messageToken: '',
    authLoading: false,
    authError: '',
    backUrl: '',
  },
  resolvers: {
    Mutation: {},
  },
};

const clientConfigs = { uri: graphqlUrl, clientState };

clientConfigs.request = function(operation) {
  const authToken = window.authToken ? window.authToken : null;
  if (authToken) {
    let headerToken = window.companyId
      ? `Bearer ${window.companyId}:${authToken}`
      : `Bearer ${authToken}`;

    if (window.companyId && window.userId) {
      headerToken = `Bearer ${window.companyId}:${window.userId}:${authToken}`;
    }

    operation.setContext({
      headers: {
        authorization: headerToken,
      },
    });
  }
};

clientConfigs.onError = function({ graphQLErrors, networkError }) {
  if (graphQLErrors && _.isArray(graphQLErrors) && graphQLErrors.length > 0) {
    if (graphQLErrors[0].message === 'Unauthorized') {
      // fbAuth.signOut();
    }
  }
  console.log(graphQLErrors, networkError);
};

const client = new ApolloClient(clientConfigs);

// console.log(client);

// syncHistoryWithStore(client);

export const history = _history;
export default client;
