import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import './styles.scss';

class NotFound extends Component {
  render() {
    return (
      <div className="page-content page-notfound">
        <div className="container-center">
          <div className="error-container">
            <div className="container">
              <div className="err-container">
                <div className="err-code-container">
                  <div className="err-code">
                    <div className="err-code-title">400</div>
                  </div>
                </div>
                <div className="err-desc">Sorry, Page Not Found</div>
                <Link to="/ui" className="button">
                  Go Back to Home Page
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
