import client from 'GraphqlClient';
import { fbMessaging, fbAuth } from 'helpers/firebase';

let initAuth = false;

function init(resolve) {
  return fbMessaging
    .requestPermission()
    .then(() => {
      subAuth();
      subTokenChange(resolve);
      getMessageToken();
      messageTokenOnChange();
    })
    .catch(err => {
      subAuth();
      subTokenChange(resolve);
    });
}

function subAuth() {
  return fbAuth.onAuthStateChanged(user => {
    if (!user) {
      client.writeData({ data: { authLoading: false, isLoggedIn: false } });
      window.currentUser = null;
      window.authToken = null;
      window.companyId = null;
      window.personId = null;
      window.userId = null;
      window.oriAuth = null;
      return;
    }

    return;
  });
}

function subTokenChange(resolve) {
  return fbAuth.onIdTokenChanged(user => {
    window.authToken = null;
    if (!user) {
      if (!initAuth) {
        initAuth = true;
        if (resolve) {
          resolve();
        }
      }
      return;
    }
    window.userId = user.uid;
    return user
      .getIdTokenResult()
      .then(idTokenResult => {
        window.oriAuth = { ...user, ...idTokenResult.claims };
        window.companyId =
          idTokenResult.claims && idTokenResult.claims.companyId
            ? idTokenResult.claims.companyId
            : null;
        window.personId =
          idTokenResult.claims && idTokenResult.claims.personId
            ? idTokenResult.claims.personId
            : null;
        window.authToken = idTokenResult.token;
        if (!initAuth) {
          initAuth = true;
          if (resolve) {
            resolve();
          }
        }
        client.writeData({ data: { isLoggedIn: true, authLoading: false } });
        return;
      })
      .catch(err => {
        fbAuth.signOut();
        if (!initAuth) {
          initAuth = true;
          if (resolve) {
            resolve();
          }
        }
        return;
      });
  });
}

function getMessageToken() {
  fbMessaging.getToken().then(function(currentToken) {
    if (currentToken) {
      client.writeData({ data: { messageToken: currentToken } });
    } else {
      client.writeData({ data: { messageToken: '' } });
    }
  });
}

function messageTokenOnChange() {
  return fbMessaging.onTokenRefresh(() => {
    getMessageToken();
  });
}

export default init;
