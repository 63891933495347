import React from 'react';

import './styles.scss';

function LoadingComponent(props) {
  if (props.error) {
    return (
      <div className="loader-component">
        <div className="container-center error-component">
          <div className="error">
            Error! Please refresh the page
            <button
              onClick={() => {
                return window.location.reload();
              }}
              className="reload-btn ant-btn ant-btn-circle ant-btn-icon-only"
            >
              <i className="fa fa-refresh" />
            </button>
          </div>
        </div>
      </div>
    );
  } else if (props.pastDelay) {
    return <div className="loader-block" />;
  } else {
    return null; // Avoiding Flash Of Loading Component (<200ms)
  }
}

export default LoadingComponent;
