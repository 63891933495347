import React from 'react';
import ReactDOM from 'react-dom';
import apolloClient from 'GraphqlClient';
import initAuth from 'startup/initAuth';
import { ApolloProvider } from 'react-apollo';

import './startup/initial';
import './styles/index.scss';
import Root from './Root';

// import * as serviceWorker from './serviceWorker';
// console.log(process.env);

initAuth(function() {
  ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <Root />
    </ApolloProvider>,
    document.getElementById('root'),
  );
});
// window.Intercom('boot', {
//   app_id: 'owflpnvl',
//   app: 'admin',
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
