import accounting from 'accounting-js';

accounting.settings = {
  currency: {
    symbol: 'Rp', // default currency symbol is '$'
    format: '%s%v', // controls output: %s = symbol, %v = value/number (can be object: see below)
    decimal: ',', // decimal point separator
    thousand: '.', // thousands separator
    precision: 0, // decimal places
  },
  number: {
    precision: 0, // default precision on numbers is 0
    thousand: '.',
    decimal: ',',
  },
};

accounting.settings.precision = 0;
accounting.settings.thousand = '.';
accounting.settings.decimal = ',';

window.accounting = accounting;

export const formatMoney = function(val) {
  return accounting.formatMoney(val, accounting.settings.currency);
};
export const formatNumber = function(val) {
  return accounting.formatNumber(val, accounting.settings.number);
};
export const parseNumber = function(val) {
  return accounting.unformat(val, ',');
};

export default accounting;
