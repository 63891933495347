import React from 'react';
import { Layout, ConfigProvider } from 'antd';
import en_GB from 'antd/lib/locale-provider/en_GB';
import { history } from './GraphqlClient';
import { Router, Route } from 'react-router-dom';

import App from './App';

class Root extends React.Component {
  render() {
    return (
      <ConfigProvider locale={en_GB}>
        <Layout className="app-layout">
          <Router history={history}>
            <Route component={App} />
          </Router>
        </Layout>
      </ConfigProvider>
    );
  }
}

export default Root;
