import moment from 'moment';

import 'moment/locale/id';
import 'moment/locale/en-gb';

moment.locale('en-gb');

const client = moment;

window.moment = client;

export default client;
