import './day';
import './validator';
import './moment';
import 'helpers/accounting';
import fitty from 'fitty/dist/fitty.module';
import { message } from 'antd';

message.config({
  duration: 5,
});

let isDev = false;

if (process && process.env && process.env.NODE_ENV === 'development') {
  isDev = true;
}

window.fitty = fitty;
window.__DEV__ = isDev;

export default isDev;
