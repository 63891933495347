import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/storage';
import 'firebase/functions';

const fbConfig = {
  apiKey: 'AIzaSyB32Sj4nfHxNG816NYZZd_7pJBZrmApRMg',
  authDomain: 'epegawai.firebaseapp.com',
  databaseURL: 'https://epegawai.firebaseio.com',
  projectId: 'epegawai',
  storageBucket: 'epegawai.appspot.com',
  messagingSenderId: '619378537181',
  appId: '1:619378537181:web:93ba56523a1ac1c81ba134',
};

const fbApp = firebase.initializeApp(fbConfig, '[default]');
fbApp.auth().languageCode = 'id';

const _googleAuthProvider = new firebase.auth.GoogleAuthProvider();
_googleAuthProvider.addScope('https://www.googleapis.com/auth/userinfo.email');

const messaging = fbApp.messaging();

messaging.usePublicVapidKey(
  'BIwimX6jgbNrlVNtGJnS0eWY6kEqlOILOcSk3aqWZf5IzAKit2RynmGqSRCIfAFdmhzS3eBDMl0Sy4NUn2Sf7SQ',
);

const _newFirebaseRecaptchaVerifier = function() {
  return new firebase.auth.RecaptchaVerifier('recaptchaWidget', {
    size: 'invisible',
  });
};

export const newFirebaseRecaptchaVerifier = _newFirebaseRecaptchaVerifier;

export const googleAuthProvider = _googleAuthProvider;
export const fbAuth = fbApp.auth();
export const fbDb = fbApp.database();
export const fbFirestore = fbApp.firestore();
export const fbStorage = fbApp.storage();
export const fbMessaging = messaging;
export const fbFunctions = fbApp.functions();
